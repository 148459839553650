import React from 'react'
import {Box, Text} from '@primer/components'

export default function OpenSource() {
  return (
    <Box bg="blue.2">
      <Box color="black" px={5} py={5} mx="auto" className="container-xl">
        <Text as="p">&copy; Andrew Boyarshin {new Date().getFullYear()}</Text>
      </Box>
    </Box>
  )
}
