import React from 'react'
import {Box, Heading, StyledOcticon, Text} from '@primer/components'
import styled from 'styled-components'
import LinkLight from './LinkLight'
import IndexGrid from './IndexGrid'
import MeHeroPng from '../assets/me-photo-hero.png'
import MeHeroWebp from '../assets/me-photo-hero.webp'
import {MarkGithubIcon, MortarBoardIcon, BriefcaseIcon} from '@primer/octicons-react'
import {ReactComponent as TelegramIcon} from '../svg/telegram.svg'

const DOT = '・'

const StrokeSpan = styled.span`
  fill: ${props => props.strokeColor};
  vertical-align: middle;
`

export default function Hero() {
  return (
    <Box pt={[4, 2, 5]}>
      <IndexGrid my={0} flexDirection={['row', 'row', 'row-reverse']} alignItems="flex-end">
        <Box px={5} width={[10 / 12, 8 / 12, 6 / 12, 5 / 12]} mx="auto">
          <picture>
            <source srcSet={MeHeroWebp} type="image/webp" />
            <source srcSet={MeHeroPng} type="image/png" />
            <img src={MeHeroPng} width="100%" height={null} alt="My Photo" />
          </picture>
        </Box>
        <Box px={5} my={4} width={[1, 1, 1, 7 / 12]}>
          <Heading color="blue.4" mb={4} fontSize={[32, 48, 64]} fontWeight="bold">
            Andrew Boyarshin
          </Heading>
          <Text as="div" color="blue.2">
            Kernel Development, Windows Internals & Reverse Engineering
          </Text>
          <Text as="div" color="blue.2">
            Managed languages and runtime internals enthusiast
          </Text>
          <Text as="div" color="blue.2" mt={2}>
            <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" mr={1} /> Student at Novosibirsk State University
          </Text>
          <Text as="div" color="blue.2">
            <StyledOcticon icon={BriefcaseIcon} verticalAlign="middle" mr={1} /> Currently unavailable for hire
          </Text>
          <Text fontFamily="mono" as="p" color="blue.3" mt={5}>
            <LinkLight mr={1} fontSize={[0, 1, 2]} href="https://github.com/andrew-boyarshin">
              <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" /> GitHub
            </LinkLight>
            {DOT}
            <LinkLight ml={1} fontSize={[0, 1, 2]} href="https://t.me/andrew_boyarshin">
              <StrokeSpan strokeColor={'#79b8ff'}>
                <TelegramIcon />
              </StrokeSpan>{' '}
              Telegram
            </LinkLight>
          </Text>
        </Box>
      </IndexGrid>
    </Box>
  )
}
